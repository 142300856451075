import React from 'react'
import { useField } from 'formik'

import {
  CheckboxProps,
  CheckboxAcceptProps,
} from '@components/forms/Checkbox/interface'

const withCheckbox = (Component: React.FC<CheckboxProps>) => {
  function Hoc({ onChange, name, ...props }: CheckboxAcceptProps) {
    const [field] = useField(name)

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
      field.onChange(e)

      if (onChange) {
        onChange(e)
      }
    }

    const newProps = {
      ...props,
      ...field,
      onChange: handleChange,
    }

    return <Component {...newProps} />
  }

  return Hoc
}

export { withCheckbox }
