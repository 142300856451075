import { createContext, useContext } from 'react'
import { Client } from '@client/Client'

interface AppClientProviderType {
  children: React.ReactNode
  client: Client
}

const ApiContext = createContext<{
  client: Client | null
}>({
  client: null,
})

export function AppClientProvider({ children, client }: AppClientProviderType) {
  const value = {
    client,
  }

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>
}

export function useClient() {
  return useContext(ApiContext).client
}
