import { gql } from 'graphql-request'

export const GET_CATEGORY_OPTIONS = gql`
  query GetCategoryOptions($bookType: [BookType!]) {
    categories(bookType: $bookType) {
      id
      name
    }
  }
`
