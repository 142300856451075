import React from 'react'
import cn from 'classnames'
import { CheckboxProps } from '@components/forms/Checkbox/interface'
import { CheckIcon } from '@components/Icons'

export function Checkbox({
  label,
  value,
  disabled,
  className,
  labelClassName,
  ...props
}: CheckboxProps) {
  return (
    <label className={cn('flex items-center w-full cursor-pointer', className)}>
      <div className='relative h-[24px]'>
        <input
          className={cn(
            'appearance-none h-6 w-6 bg-white border border-gray rounded-[8px] checked:bg-primary checked:border-primary focus:outline-none',
            {
              'bg-[#f4f4f4] border-gray checked:bg-white checked:border-gray':
                disabled,
            }
          )}
          type='checkbox'
          checked={value}
          disabled={disabled}
          {...props}
        />
        {!disabled && (
          <CheckIcon
            className='text-white absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2'
            width='18'
            height='18'
          />
        )}
      </div>
      {label && <div className={cn('ml-[10px]', labelClassName)}>{label}</div>}
    </label>
  )
}
