import { useState } from 'react'
import { useInfiniteQuery } from 'react-query'

import { client } from '@client/init'
import { SortByType, SortingType } from '@components/Table/interface'
import { EbookSectionProps, WithEbookSectionProps } from './interface'

export function withEbookSection(Component: React.FC<EbookSectionProps>) {
  function WithEbookSection({ queryParam }: WithEbookSectionProps) {
    const [sort, setSort] = useState<SortByType>({
      key: 'buyPrice',
      order: SortingType.DESC,
    })

    function handleSort({ key, order }: SortByType) {
      setSort(prev => ({ ...prev, key, order }))
    }
    const { fetchNextPage, data, isLoading, isFetchingNextPage } =
      useInfiniteQuery(
        ['bought-ebook-reader', queryParam, sort],
        ({ pageParam = 1 }) =>
          client?.dashboardClient.getBoughtEbookReader({
            startDate: queryParam.startDate,
            endDate: queryParam.endDate,
            userId: Number(queryParam.userId),
            page: pageParam,
            ...sort,
          }),
        {
          enabled: !!queryParam.userId,
          getNextPageParam: lastPage => {
            if (lastPage && lastPage.data.length) return lastPage.page + 1

            return undefined
          },
        }
      )

    function handleFetchNextPage() {
      if (!isFetchingNextPage) fetchNextPage()
    }

    const componentProps = {
      data,
      isLoading,
      sort,
      handleSort,
      handleFetchNextPage,
    }

    return <Component {...componentProps} />
  }

  return WithEbookSection
}
