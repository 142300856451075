import { Expose } from 'class-transformer'

export class Pagination<T> {
  @Expose()
  data: T[]

  @Expose()
  total: number

  @Expose()
  page: number
}
