import { useMemo } from 'react'

import { Table } from '@components/Table'
import { EbookSectionProps } from './interface'
import { columns } from './column'

export function EbookSection({
  data,
  isLoading,
  sort,
  handleFetchNextPage,
  handleSort,
}: EbookSectionProps) {
  // TODO change name
  const ebookData = useMemo(
    () =>
      data?.pages
        .map(page => page.data)
        .reduce((prev, curr) => prev.concat(curr), []) || [],
    [data]
  )
  return (
    <div className='mb-[30px]'>
      <div className='flex'>
        <div className='flex-1 font-mitr text-[24px] font-medium text-secondary mb-[20px]'>
          อีบุ๊กที่ซื้อแล้ว
        </div>

        <div className='text-[14px] text-secondary-100 font-light'>
          อีบุ๊กทั้งหมด:
          <span className='ml-[10px] mr-[5px] text-secondary font-medium text-base font-mitr'>
            {data?.pages[0].total ? data?.pages[0].total.toLocaleString() : '-'}
          </span>
          เรื่อง
        </div>
      </div>

      <Table
        data={ebookData}
        columns={columns}
        onSort={handleSort}
        fixedHeader
        className='mb-[30px] max-h-[400px] pr-[20px]'
        onWaypointEnter={handleFetchNextPage}
        isLoading={isLoading}
        initialSort={{ key: sort.key, order: sort.order }}
      />
    </div>
  )
}
