import { Expose } from 'class-transformer'

export class StoryFieldType {
  @Expose()
  name: string

  @Expose()
  content: string

  maxCharacter?: number
}
