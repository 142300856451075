import { Expose, Type } from 'class-transformer'
import { BookTopChartsType } from '@models/story/BookTopChartsType'

export class BookTopChartsResponse {
  @Expose()
  @Type(() => BookTopChartsType)
  data: BookTopChartsType[]

  @Expose() total: number

  @Expose() page: number
}
