import { Expose, Transform, Type } from 'class-transformer'
import { BookCharacterRoleType } from './BookCharacterRoleType'
import { BookCharacterRankType } from './BookCharacterRankType'

class CharacterNextRankType {
  @Expose()
  id: number

  @Expose()
  name: string
}

export class BookCharacterType {
  @Expose()
  id: number

  @Expose()
  name: string

  @Expose()
  imgPath: string

  @Expose()
  resizeImgPath: string

  @Expose()
  description: string

  @Expose()
  coinsTotal: number

  @Expose()
  coinsToNextLevel: number

  @Expose()
  donatable: boolean

  @Expose()
  visible: boolean

  @Type(() => BookCharacterRoleType)
  characterRole: BookCharacterRoleType

  @Type(() => BookCharacterRankType)
  characterRank: BookCharacterRankType

  @Expose({ name: 'nextRank' })
  @Type(() => CharacterNextRankType)
  characterNextRank: CharacterNextRankType

  @Expose()
  publishedAt: string

  @Expose()
  createdAt: string

  @Expose()
  updatedAt: string

  @Expose()
  @Transform(({ obj }) => {
    const now = new Date().valueOf()

    return now < new Date(obj.publishedAt).valueOf()
  })
  isShowPublishedAt: boolean
}
