import { Expose } from 'class-transformer'

export class BookCharacterRankType {
  @Expose()
  id: number

  @Expose()
  name: string

  @Expose()
  startCoins: number

  @Expose()
  endCoins: number

  @Expose()
  imgPath: string
}
