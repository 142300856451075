import { gql } from 'graphql-request'

export const GET_CHARACTERS = gql`
  query GetCharacterByBook($bookId: Int!) {
    getCharacterByBook(bookId: $bookId) {
      id
      name
      imgPath
      resizeImgPath
      description
      coinsTotal
      coinsToNextLevel
      characterRole {
        id
        name
      }
      characterRank {
        id
        name
        startCoins
        endCoins
        imgPath
      }
      nextRank {
        id
        name
      }
      donatable
      visible
      publishedAt
      createdAt
      updatedAt
    }
  }
`
